export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
          Agni Solar solutions is one of the well-established solar energy consultant ,integrator of solar plants and suppliers of wide range of solar products including...
          </p>
          <ol>
            <li>Solar PV Modules</li>
            <li>Solar Inverters</li>
            <li>Solar Power Plant Systems-On Grid/Off Grid</li>
            <li>Solar Street Lighting System</li>
            <li>Solar Water Pumping System</li>
            </ol>
          {/* {props.products && props.products.map((p,i)=>{
              console.log(p.product);
             <div style ={{ backgroundColor: 'white',
             width: '50px',
             marginBottom: '10px',
             padding: '10px',
             color: 'green',
             boxShadow: 'rgb(0,0,0,0.44) 0px 5px 5px'}}>{p.product}</div>
              
            })} */}
        </div>
       
            
          
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
