import 'font-awesome/css/font-awesome.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';
export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Benefits</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-4 col-md-3'>
                  {' '}
                  <i className = {d.icon}></i>
                  <FontAwesomeIcon icon="fa-solid fa-bolt" />
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>                 
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
